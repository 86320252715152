import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {PROMO_PICTURE_DELAY_MS, URL_MEDIA_IMG} from "../../constants";

const useStyles = makeStyles((theme) => ({
        container: {
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(0),
        },
        img: {
            border: 0,
            width: '95vw'
        }
    }
))


export default function MediaArray(props) {
    const {media} = props;
    const classes = useStyles();
    const idArray = [];
    const numberofMedia = media.length;
    const [position, setPosition] = useState(Math.floor(Math.random() * numberofMedia));
    const [videoList, setVideoList] = useState(document.getElementsByTagName("video"));
    const startSmall = (position + 1) % numberofMedia;
    const re = /(?:\.([^.]+))?$/;

    for (let i = startSmall; i < startSmall + media.length; i++) {
        idArray.push(i % numberofMedia)
    }

    const generateSrc = (id, mediaID) => {
        for (let i = 0; i < videoList.length; i++) {
            if (mediaID === videoList[i]['id']) {
                return URL_MEDIA_IMG + media[id]["location"] + '#t=' + videoList[i].currentTime
            }
        }
    }

    useEffect(() => {
        let timer1 = setTimeout(() => {
            setVideoList(document.getElementsByTagName("video"));
            setPosition((position + 1) % numberofMedia);
        }, PROMO_PICTURE_DELAY_MS);
        return () => {
            clearTimeout(timer1);
        }
    }, [position, numberofMedia, setVideoList])

    return (
        <div>
            {idArray.map((id, index) => {
                if (re.exec(media[id]['location'])[1] === "mp4") {
                    return <video src={generateSrc(id, media[id]['id'])}
                                  className={classes.img}
                                  muted
                                  autoPlay
                                  loop={true}
                                  key={index}
                                  id={media[id]['id']}
                    />
                } else {
                    return <img
                        src={URL_MEDIA_IMG + media[id]["location"]}
                        alt={media.alt}
                        key={index}
                        className={classes.img}
                        id={media[id]['id']}
                    />
                }

            })}
        </div>
    );
}
