import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import '../individualDisplay.css'
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    longCell: {
        maxWidth: '10px',
    }
}));

export default function Emissions(props) {
    const {vehicleData} = props;
    const classes = useStyles();
    return (
        <TableContainer component={Paper} variant="outlined">
            <Table aria-label="spanning table">
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={12}>
                            <b>Kraftstoffverbrauch *</b>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={3} className={classes.longCell}>
                            kombiniert
                        </TableCell>
                        <TableCell>
                            {vehicleData.Emission.ConsumptionCombined.FormattedValue || "-"}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={3}>
                            innerorts
                        </TableCell>
                        <TableCell>
                            {vehicleData.Emission.ConsumptionInTown.FormattedValue || "-"}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={3}>
                            außerorts
                        </TableCell>
                        <TableCell>
                            {vehicleData.Emission.ConsumptionOutOfTown.FormattedValue || "-"}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={12}>
                            <b>CO 2 - Emissionen * </b>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={3}>
                            kombiniert
                        </TableCell>
                        <TableCell>
                            {vehicleData.Emission.CO2Emission.FormattedValue || "-"}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={3}>
                            Effizienzklasse
                        </TableCell>
                        <TableCell>
                            {vehicleData.Emission.EfficiencyClass.Name || "-"}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}