import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    longCell: {
        minWidth: '145px',
    },
    textContainer: {
        display: 'block',
        maxWidth: 200,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }
}));

export default function Secondary_data(props) {
    const {vehicleData} = props;
    const classes = useStyles();
    return (
        <TableContainer component={Paper} variant="outlined">
            <Table aria-label="spanning table">
                <TableBody>
                    <TableRow className={classes.tableRow}>
                        <TableCell colSpan={3} className={classes.longCell}>
                            <b>Hubraum</b>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            <div className={classes.textContainer}>
                                {vehicleData.EngineSize.FormattedValue || "-"}
                            </div>
                        </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell colSpan={3}>
                            <b>Anzahl Gänge</b>
                        </TableCell>
                        <TableCell>
                            <div className={classes.textContainer}>
                                {vehicleData.Gears || "-"}
                            </div>
                        </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell colSpan={3}>
                            <b>Sitzplätze</b>
                        </TableCell>
                        <TableCell>
                            <div className={classes.textContainer}>
                                {vehicleData.Seats || "-"}
                            </div>
                        </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell colSpan={3}>
                            <b>Anzahl Türen</b>
                        </TableCell>
                        <TableCell>
                            <div className={classes.textContainer}>
                                {vehicleData.DoorsCombined || "-"}
                            </div>
                        </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell colSpan={3}>
                            <b>Innenfarbe</b>
                        </TableCell>
                        <TableCell>
                            <div className={classes.textContainer}>
                                {vehicleData.ColorInterieur.AdditionalValue || "-"} ({vehicleData.ColorInterieur.Value || "-"})
                            </div>
                        </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell colSpan={3}>
                            <b>Polsterung</b>
                        </TableCell>
                        <TableCell>
                            <div className={classes.textContainer}>
                                {vehicleData.Upholstery.Value || "-"}
                            </div>
                        </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell colSpan={3}>
                            <b>Außenfarbe</b>
                        </TableCell>
                        <TableCell>
                            <div className={classes.textContainer}>
                                {vehicleData.Color.AdditionalValue || "-"} ({vehicleData.Color.Value || "-"})
                            </div>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
