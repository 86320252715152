import React, {useContext} from "react";
import "./individualDisplay.css"
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {MyContext} from "../MyContext";
import MediaArray from "./promotions/MediaArray";
import Progress from "./promotions/Progress";
import Service from "./Service";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

const Promotions = (props) => {
    const {rootState} = useContext(MyContext);
    const {template, delay} = props;
    const media = rootState.media;
    const classes = useStyles();
    let currentMedia = []
    for (let i = 0; i < media.length; i++) {
        if (media[i]['categorie'] === template) {
            currentMedia.push(media[i])
        }
    }
    shuffleArray(currentMedia);
    if (currentMedia.length === 0) {
        return (<Service mediaError={true}/>);
    } else {
        return (
            <Container maxWidth="lg" className={classes.container}>
                <Grid container direction='column' spacing={2}>
                    <Grid container item direction='column' spacing={2}>
                        <Grid item>
                            <Progress delay={delay}/>
                        </Grid>
                        <Grid item>
                            <MediaArray media={currentMedia}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        );
    }


}
export default Promotions;
