import React from 'react';
import Container from '@material-ui/core/Container';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

const ahSchmidt = makeStyles((theme) => ({
        container: {
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(0),
            maxHeight: 80
        },
        left: {
            marginTop: 10,
            marginBottom: 10
        },
        right: {
            textAlign: "right",
            marginTop: 10,
            marginBottom: 10
        },
        paper: {
            padding: theme.spacing(1),
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(0),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
        },
        callToAction: {
            background: 'linear-gradient(0deg, #014686 30%, #1e3177 90%)',
            color: "white"
        },
    }
));
const fahrzeugland = makeStyles((theme) => ({
        container: {
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(0),
            maxHeight: 80
        },
        left: {
            marginTop: 10,
            marginBottom: 10
        },
        right: {
            textAlign: "right",
            marginTop: 10,
            marginBottom: 10
        },
        paper: {
            padding: theme.spacing(1),
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(0),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
        },
        callToAction: {
            background: 'linear-gradient(0deg, #e52820 30%, #900802 90%)',
            color: "white"
        },
    }
));

export default function CallToAction(props) {
    const {vehicleData} = props;
    let classes = ahSchmidt();
    if (vehicleData.Branch.BranchId === 3672) {
        classes = fahrzeugland();
    }
    const offerNumber = `Angebotsnr: ${vehicleData.OfferNumber}`
    return (
        <Paper className={classes.callToAction} elevation={4}>
            <Container maxWidth="lg" className={classes.container}>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <h3 className={classes.left}>{offerNumber}</h3>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <h3 className={classes.right}>Sofort Verfügbar</h3>
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    );
}
