import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import QRCodes from "react-qr-code";

const useStyles = makeStyles((theme) => ({
        container: {
            padding: theme.spacing(1),
        },
        img: {
            border: 0,
        }
    }
))

export default function QRCode(props) {
    const classes = useStyles();
    const {vehicleData, url} = props;

    const paramsVH = new URLSearchParams({
        vi: vehicleData.VehicleId,
    });

    return (
        <Container maxWidth="lg" className={classes.container}>
            <QRCodes value={url + paramsVH} size={100}/>
        </Container>
    );
}
/*
    const params = new URLSearchParams({
        data: url + paramsVH,
        size: "100x100"
    });
            <img onError={(e) => {
                e.target.onerror = null;
                e.target.src = URL_QR + params
            }}
                 src={"data:image/jpg;base64, " + vehicleData["qr"]}
                 className={classes.img}
                 alt={''}/>
 */
