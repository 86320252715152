import React, {useContext, useEffect, useState} from "react";
import "../individualDisplay.css"
import LinearProgress from "@material-ui/core/LinearProgress";
import {MyContext} from "../../MyContext";


const Progress = (props) => {
    const {rootState} = useContext(MyContext);
    const {delay} = props;
    const display = rootState.data;
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const timer5 = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = (1 / parseInt(delay)) * 1.6667;
                return Math.min(oldProgress + diff, 100);
            });

        }, 1000);
        return () => {
            clearInterval(timer5);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // ADD Color of Linear Progress Bar
    let color = 'primary';
    if (display.branchID === '3672') {
        color = 'secondary';
    }
    return (
        <LinearProgress variant="determinate" color={color} value={progress}/>
    );

}
export default Progress;
