// General
export const PICTURE_DELAY_MS = 1000 * 5;
export const CONVERT_MIN_TO_MS = 1000 * 60;
export const PROMO_PICTURE_DELAY_MS = 1000 * 8;
export const CHANGE_DELAY = 2;
export const API_KEY = "jBsGMF5eqtgea8pqGrUM23UALjuzrSYn"

// New Const
export const BASE_PLUS_API_URL = process.env.NODE_ENV === 'production' ? 'https://kk-display.de/v1/controller/' : 'https://kk-display.de/dev/controller/';
export const CARZILLA_URL = process.env.NODE_ENV === 'production' ? BASE_PLUS_API_URL + 'proxy.php' : '/api/carzilla';
export const GET_DISPLAY = process.env.NODE_ENV === 'production' ? BASE_PLUS_API_URL + 'readDisplay.php' : '/api/readDisplay';
export const GET_MEDIA = process.env.NODE_ENV === 'production' ? BASE_PLUS_API_URL + 'readMedia.php' : '/api/readMedia';
export const URL_MEDIA_IMG = 'https://kk-display.de/';
export const CARZILLA_URL_IMG = BASE_PLUS_API_URL + 'readImg.php?';

// Old Const
export const BASE_URL = 'https://kk-display.de/';
export const API_DISPLAY_URL = process.env.NODE_ENV === 'production' ? BASE_PLUS_API_URL + 'readData.php?' : '/api/data/readData.php?';
export const API_PROMO_URL = process.env.NODE_ENV === 'production' ? BASE_PLUS_API_URL + 'readCategories.php?' : '/api/promo/readCategories.php?';
export const API_PROMO_IMG_URL = process.env.NODE_ENV === 'production' ? BASE_PLUS_API_URL + 'readPromoImg.php?' : '/api/promoImg/readPromoImg.php?';
