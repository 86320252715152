import React, {useContext, useEffect, useState} from "react";
import "./components/individualDisplay.css"
import {MyContext} from "./MyContext";
import {CONVERT_MIN_TO_MS} from "./constants";
import IndividualDisplay from "./components/IndividualDisplay";
import Promotions from "./components/Promotions";
import Service from "./components/Service";


const ViewController = () => {
    const {rootState} = useContext(MyContext);
    const display = rootState.data;
    const vehicles = rootState.vehicles;
    const [delay, setDelay] = useState(0);
    const [index, setIndex] = useState(0);
    let views = [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    let delays = [];
    let randomVehicle = Math.floor(Math.random() * vehicles.length);

    if (parseInt(display.individual) !== 0) {
        if (vehicles.length > 1) {
            views.push("individual");
            delays.push(display.individual * CONVERT_MIN_TO_MS);
        } else {
            views.push("individual error")
            delays.push(display.individual * CONVERT_MIN_TO_MS);
        }
    }
    if (parseInt(display.equipment) !== 0) {
        views.push("equipment");
        delays.push(display.equipment * CONVERT_MIN_TO_MS);
    }
    if (parseInt(display.special) !== 0) {
        views.push("special");
        delays.push(display.special * CONVERT_MIN_TO_MS);
    }
    if (parseInt(display.fun) !== 0) {
        views.push("fun");
        delays.push(display.fun * CONVERT_MIN_TO_MS);
    }

    useEffect(
        () => {
            const timer2 = setInterval(() => {
                setIndex((i) => (i + 1) % views.length)
                // console.log('Timer', index, delay)
            }, delay);
            return () => {
                clearInterval(timer2)
            }
        }
    )
    useEffect(
        () => {
            setDelay(delays[index])
        }, [index, delays]
    )

    if (views[index] === "individual") { // IndividualDisplay
        return (
            <div className="App">
                <IndividualDisplay randomVehicle={randomVehicle}/>
            </div>
        )
    } else if (views[index] === "equipment") { // Equipment
        const template = "equipment"
        return (
            <div className="App">
                <Promotions template={template} delay={display.equipment}/>
            </div>
        )
    } else if (views[index] === "special") { // Promotions/Special
        const template = "special"
        return (
            <div className="App">
                <Promotions template={template} delay={display.special}/>
            </div>
        )
    } else if (views[index] === "fun") { // Fun
        const template = "fun"
        return (
            <div className="App">
                <Promotions template={template} delay={display.fun}/>
            </div>
        )
    } else if (views[index] === "individual error") { // Fun
        return (
            <div className="App">
                <Service individualError={true}/>
            </div>
        )
    } else {
        return (<Service/>)
    }

}
export default ViewController;

/*         const template = "fun"
        return (
            <div className="App">
                <Promotions template={template} delay={display.fun}/>
            </div>
        )

 */
