import React, {Component, createContext} from 'react';
import axios from 'axios';
import {CARZILLA_URL, GET_DISPLAY, GET_MEDIA, CARZILLA_URL_IMG, URL_MEDIA_IMG} from "./constants";
import imageToBase64 from "image-to-base64/browser";

export const MyContext = createContext();

class MyContextProvider extends Component {
    // Root State
    state = {
        data: null,
        media: null,
        vehicles: null,
        currentVehicle: 0,
    };

    setDisplay = async (d, c) => {
        const response = await axios.post(GET_DISPLAY, {
            display: d,
            customer: c
        });

        if (response.data.success && response.data.display) {
            this.setState({
                ...this.state,
                data: response.data.display[0]
            })
            return false
        } else {
            // Return to set error in Service Component
            return true
        }
    }

    setMedia = async () => {
        const response = await axios.post(GET_MEDIA, {
            branchID: this.state.data.branchID
        })
        if (response.data.success && response.data.media) {
            for (let i = 0; i < response.data.media.length; i++) {
                response.data.media[i]["img"] = [[""]]
                // console.log(response.data.vehicles)
            }
            this.setState({
                ...this.state,
                media: response.data.media
            })
            return false
        } else {
            // Return to set error in Service Component
            return true
        }
    }

    setMediaImgs = async () => {
        let media = this.state.media;
        for (let i = 0; i < media.length; i++) {
            const url = URL_MEDIA_IMG + media[i]["location"];
            console.log(url)
            await imageToBase64(url).then((response) => {
                media[i]["img"] = response;
            }).catch((error) => {
                console.log(error); // Logs an error if there was one
            })
            this.setState({
                ...this.state,
                media: media
            })
        }
    }

    setCarZilla = async () => {
        const response = await axios.post(CARZILLA_URL, {
            Randomize: true,
            SuppressRawVehicles: false,
            vehiclePerPage: 200,
            DisableCache: true,
            searchId: this.state.data.searchID,
            searchParams: [{Branches: [this.state.data.branchID]}]
        })
        if (response.status === 200) {
            for (let i = 0; i < response.data.vehicles.length; i++) {
                response.data.vehicles[i]["img"] = [[""]]
                // console.log(response.data.vehicles)
            }
            this.setState({
                ...this.state,
                vehicles: response.data.vehicles
            })
            return false
        } else {
            // Return to set error in Service Component
            return true
        }
    }

    setCarZillaImgs = async () => {
        let vehicles = this.state.vehicles;
        let vehicle = [];

        // vehicles.length
        for (let i = 0; i < vehicles.length; i++) {
            for (let j = 0; j <= vehicles[i].CountImages; j++) {
                const params = new URLSearchParams({
                    vid: vehicles[i].VehicleId,
                    bid: vehicles[i].Branch.BranchId,
                    format: "l",
                    ino: j
                });
                const url = CARZILLA_URL_IMG + params
                // eslint-disable-next-line no-loop-func
                await imageToBase64(url).then((response) => {
                    vehicle.push(response);
                }).catch((error) => {
                    console.log(error); // Logs an error if there was one
                })
            }
            vehicles[i]["img"] = vehicle;
            vehicle = [];
        }
        this.setState({
            ...this.state,
            vehicles: vehicles
        })
    }


    render() {
        const contextValue = {
            rootState: this.state,
            setDisplay: this.setDisplay,
            setMedia: this.setMedia,
            setMediaImgs: this.setMediaImgs,
            setCarZilla: this.setCarZilla,
            setCarZillaImgs: this.setCarZillaImgs,
        };
        return (
            <MyContext.Provider value={contextValue}>
                {this.props.children}
            </MyContext.Provider>
        );
    }

}

export default MyContextProvider;
