import {Navigate} from 'react-router-dom';
import Display from "./Display";
import Service from "./components/Service";

const routes = () => [
    {
        path: '/',
        children: [
            {path: '/d=:d&c=:c', element: <Display/>},
            {path: 'Service', element: <Service/>},
            {path: '/', element: <Navigate to='/Service'/>},
            {path: '*', element: <Navigate to='/Service'/>}
        ]
    }
];
export default routes;
