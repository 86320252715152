import React, {useState, useEffect, useContext, useRef} from 'react';
import {MyContext} from "../MyContext";
import {CHANGE_DELAY} from "../constants";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Pictures from "./vehicle/pictures";
import SecondaryData from "./vehicle/secondary_data";
import Emissions from "./vehicle/emissions";
import EssentialData from "./vehicle/essential_data";
import Address from "./vehicle/address";
import CallToAction from "./vehicle/callToAction";
import QRCode from "./vehicle/qrCode";
import Disclaimer from "./vehicle/disclaimer";
import {makeStyles} from "@material-ui/core/styles";

function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        height: '100vh',
        justifyContent: 'space-around',
        paddingBottom: theme.spacing(0)
    },
    container: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0)
    },
    paper: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    },
    paper_pic: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    },
    maxheight: {
        maxHeight: 80,
    },
    textContainer: {
        paddingLeft: 13,
        marginTop: 2,
        marginBottom: 2,
        maxHeight: 70,
    }
}));

const IndividualDisplay = (props) => {
    const {randomVehicle} = props;
    const {rootState} = useContext(MyContext);
    const [progress, setProgress] = useState(0);
    const [currentVehicle, setCurrentVehicle] = useState(randomVehicle);
    const vehicles = rootState.vehicles;
    const display = rootState.data;
    const classes = useStyles();

    useInterval(() => {
        setProgress((oldProgress) => {
            if (oldProgress === 100) {
                if (currentVehicle + 1 === vehicles.length) {
                    setCurrentVehicle(0);
                    return 0;
                } else {
                    setCurrentVehicle(currentVehicle + 1);
                    return 0;
                }
            }
            const diff = CHANGE_DELAY;
            return Math.min(oldProgress + diff, 100);
        });

    }, 1000);

    const vehicleData = vehicles[currentVehicle];
    // ADD Color of Linear Progress Bar
    let color = 'primary';
    if (vehicleData.Branch.BranchId === 3672) {
        color = 'secondary';
    }
    return (
        <div>
            <Container className={classes.container}>
                <Grid container direction='column' spacing={0} className={classes.gridContainer} columns={12}>
                    <Grid container item direction='column' spacing={2}>
                        <Grid item>
                            <LinearProgress variant="determinate" color={color} value={progress}/>
                        </Grid>
                        <Grid item>
                            <Paper className={classes.paper} variant="outlined">
                                <h3 className={classes.textContainer}>
                                    {vehicleData.Make.Name} {vehicleData.Version} {vehicleData.VersionAdditional}
                                </h3>
                            </Paper>
                        </Grid>
                        <Grid item>
                            <Paper className={classes.paper_pic} variant="outlined">
                                <Pictures vehicleData={vehicleData}/>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container item direction='row' spacing={0} justify='space-between'>
                        <Grid container item direction='column' xs={6} justify='space-between' spacing={2}>
                            <Grid item>
                                <SecondaryData vehicleData={vehicleData}/>
                            </Grid>
                            <Grid item>
                                <Emissions vehicleData={vehicleData}/>
                            </Grid>
                        </Grid>
                        <Grid container item direction='column' xs={6} justify='space-between' spacing={1}>
                            <Grid item>
                                <EssentialData vehicleData={vehicleData}/>
                            </Grid>
                            <Grid item>
                                <Address vehicleData={vehicleData}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item direction='row' spacing={1}>
                        <Grid item xs={12}>
                            <CallToAction vehicleData={vehicleData}/>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <QRCode vehicleData={vehicleData} url={display.url}/>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <Disclaimer/>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
export default IndividualDisplay;
/*{vehicleData.Make.Name} {vehicleData.Version} {vehicleData.VersionAdditional}*/
