import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Paper from "@material-ui/core/Paper";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

const ahschmidt = makeStyles((theme) => ({
        price: {
            color: "#014686",
            marginTop: 2.5,
            marginBottom: 2.5
        }
    }
));

const fahrzeugland = makeStyles((theme) => ({
        price: {
            color: "#e52820",
            marginTop: 2.5,
            marginBottom: 2.5
        }
    }
));

const ahschmidt2 = makeStyles((theme) => ({
        price: {
            color: "#014686",
            marginTop: 2.5,
            marginBottom: 2.5
        }
    }
));

const fahrzeugland2 = makeStyles((theme) => ({
        price: {
            color: "#e52820",
            marginTop: 2.5,
            marginBottom: 2.5
        }
    }
));

const useStyles = makeStyles((theme) => ({
        container: {
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(0),
        },
        price: {
            color: "#014686"
        },
        mwst: {
            fontSize: "small",
        },
        paper: {
            padding: theme.spacing(1),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
        },
        icon: {
            color: 'black',
            minWidth: '30px',
        }
    }
));


export default function Essential_data(props) {
    const {vehicleData, multiView} = props;
    let classes = useStyles();
    let prices;
    if (multiView) {
        prices = ahschmidt2();
        if (vehicleData.Branch.BranchId === 3672) {
            prices = fahrzeugland2();
        }
    } else {
        prices = ahschmidt();
        if (vehicleData.Branch.BranchId === 3672) {
            prices = fahrzeugland();
        }
    }
    return (
        <Paper className={classes.paper} variant="outlined">
            <div className={"pLeft"}>
                <h2 className={prices.price}>{vehicleData.SalePrice.FormattedValue}</h2>
                <p className={classes.mwst}>(MwSt. ausweisbar)</p>
            </div>
            <Divider/>
            <List component="nav" aria-label="essential">
                <ListItem>
                    <ListItemIcon className={classes.icon}>
                        <FontAwesomeIcon icon="clock"/>
                    </ListItemIcon>
                    <ListItemText>
                        {vehicleData.PreviousUsage || "Neufahrzeug"}
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon className={classes.icon}>
                        <FontAwesomeIcon icon="car"/>
                    </ListItemIcon>
                    <ListItemText>
                        {vehicleData.BodyType.Name || "-"}
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon className={classes.icon}>
                        <FontAwesomeIcon icon="calendar-alt"/>
                    </ListItemIcon>
                    <ListItemText>
                        EZ {vehicleData.InitialRegistration.FormattedValue || "-/-"}
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon className={classes.icon}>
                        <FontAwesomeIcon icon="gas-pump"/>
                    </ListItemIcon>
                    <ListItemText>
                        {vehicleData.MotorType.Name || "-"}
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon className={classes.icon}>
                        <FontAwesomeIcon icon="road"/>
                    </ListItemIcon>
                    <ListItemText>
                        {vehicleData.Mileage || "0"} km
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon className={classes.icon}>
                        <FontAwesomeIcon icon="tachometer-alt"/>
                    </ListItemIcon>
                    <ListItemText>
                        {vehicleData.Power.FormattedValue || "0"}
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon className={classes.icon}>
                        <FontAwesomeIcon icon="cogs"/>
                    </ListItemIcon>
                    <ListItemText>
                        {vehicleData.Transmission.Name || "0"}
                    </ListItemText>
                </ListItem>
            </List>
        </Paper>

    );
}
