import React from 'react';
import Container from '@material-ui/core/Container';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
        container: {
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(0),
        },
        paper: {
            padding: theme.spacing(1),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
        },
    }
));

export default function Address(props) {
    const classes = useStyles();
    const {vehicleData} = props;
    return (
        <Paper className={classes.paper} variant="outlined">
            <Container maxWidth="lg" className={classes.container}>
                <p><b>{vehicleData.Branch.BranchName || "-"}</b></p>
                <p>{vehicleData.Branch.Street || "-"} <br/>
                    {vehicleData.Branch.PostCode || "-"} {vehicleData.Branch.City || "-"} <br/>
                    Tel.: {vehicleData.Branch.Phone || "-"}
                </p>
            </Container>
        </Paper>
    );
}