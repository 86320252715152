import React from 'react';
import Container from '@material-ui/core/Container';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
        container: {
            padding: theme.spacing(1),
            fontSize: "x-small",
        },
    }
));

export default function Disclaimer() {
    const classes = useStyles();
    return (
        <Container maxWidth="lg" className={classes.container}>
            <p className={"small"}><b>Änderungen, Zwischenverkauf und Irrtümer vorbehalten.</b></p>
            <p className={"small"}>* Weitere Informationen zum offiziellen Kraftstoffverbrauch und den offiziellen
                spezifischen CO2-Emissionen neuer Personenkraftwagen können dem 'Leitfaden über den
                Kraftstoffverbrauch, die CO2-Emissionen und den Stromverbrauch neuer
                Personenkraftwagen' entnommen werden, der an allen Verkaufsstellen und bei Deutsche
                Automobil Treuhand GmbH unter www.dat.de unentgeltlich erhältlich ist.</p>
        </Container>
    );
}