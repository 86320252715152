import React, {useContext, useEffect, useState} from "react";
import 'typeface-open-sans';
import "./components/individualDisplay.css"
import {useParams} from "react-router-dom";
import Service from "./components/Service";
import {MyContext} from "./MyContext";
import ViewController from "./ViewController";
import CircularProgress from "@material-ui/core/CircularProgress";

const Display = () => {
    const {
        setCarZilla,
        setMedia,
        setDisplay
    } = useContext(MyContext);
    const [error, setError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    let {d, c} = useParams();

    const getData = async () => {
        try {
            // Get the correct display from the Database
            let errorDisplay = await setDisplay(d, c);
            if (errorDisplay) {
                setError('display');
            } else {
                // Get the corresponding media for the Display
                let errorMedia = await setMedia();
                if (errorMedia) {
                    setError('media');
                } else {
                    let errorCar = await setCarZilla();
                    if (errorCar) {
                        setError('carzilla');
                    } else {
                        setIsLoaded(true);
                        // await setCarZillaImgs();
                        // await setMediaImgs();
                        // console.log("Complete Database Sync")
                        return null
                    }
                }
            }
        } catch (err) {
            console.log("Error " + err)
            setError('general');
        }
    };

    useEffect(() => {
        let componentMounted = true;
        if (componentMounted) {
            getData()
        }
        return () => {
            componentMounted = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [d, c])

    if (error === 'display' || error === 'general') {
        return (<Service displayError={true}/>);
    } else if (error === 'media') {
        return (<Service mediaError={true}/>);
    } else if (error === 'carzilla') {
        return (<Service individualError={true}/>);
    } else if (isLoaded) {
        return (<ViewController/>);
    } else {
        return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <CircularProgress/>
        </div>;
    }
}
export default Display;
