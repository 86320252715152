import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import {create} from 'jss';
import jssPluginVendorPrefixer from 'jss-plugin-vendor-prefixer';
import {StylesProvider, jssPreset} from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faClock,
    faCar,
    faCalendarAlt,
    faGasPump,
    faRoad,
    faTachometerAlt,
    faCogs
} from '@fortawesome/free-solid-svg-icons'
import MyContextProvider from "./MyContext";
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";

const jss = create({
    plugins: [...jssPreset().plugins, jssPluginVendorPrefixer()],
});

Sentry.init({
    dsn: "https://875180428b5d4e2eafa6d8c20b6a35a8@o1058344.ingest.sentry.io/6045944",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
});

library.add(faClock, faCar, faCalendarAlt, faGasPump, faRoad, faTachometerAlt, faCogs)
ReactDOM.render(
    <React.StrictMode>
        <StylesProvider jss={jss}>
            <MyContextProvider>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </MyContextProvider>
        </StylesProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
/*        <Router>
            <Switch>
                <Route path="/d=:d&k=:k">
                    <Display/>
                </Route>
                <Route exact path="/">
                    <Display/>
                </Route>
            </Switch>
        </Router>

 */
